<template>
  <div class="admin-goods">
    <div class="title">
      <span class="s">所有商品</span>
    </div>
    <div class="search-cell">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="74px"
      >
        <el-form-item label="商品ID：">
          <el-input
            style="width: 120px"
            v-model="formInline.id"
            size="mini"
            placeholder="商品ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称：">
          <el-input
            style="width: 120px"
            v-model="formInline.name"
            size="mini"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺名称：">
          <el-input
            style="width: 120px"
            v-model="formInline.shop_name"
            size="mini"
            placeholder="店铺名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类：">
          <el-select
            style="width: 120px"
            size="mini"
            v-model="formInline.category_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属学校：">
          <el-select
            style="width: 120px"
            size="mini"
            v-model="formInline.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option
              :label="item.school_name"
              :value="item.id"
              v-for="item in schoolList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级：">
          <el-select
            style="width: 120px"
            size="mini"
            v-model="formInline.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option
              :label="item.class_name"
              :value="item.id"
              v-for="item in classList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select
            style="width: 120px"
            size="mini"
            v-model="formInline.status"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="商品下架" value="0"></el-option>
            <el-option label="上架中" value="1"></el-option>
            <el-option label="平台下架" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getGoodsList();
            "
            >查询</el-button
          >
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="ID"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="title"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="category_name"
          label="商品分类"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.status == 0
                ? "商品下架"
                : scope.row.status == 1
                ? "上架中"
                : "平台下架"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="price"
          label="价格(RM)"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="stock"
          label="库存"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="操作"
          width="180"
          l
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleLook(1, scope.row)">查看</span>
            <span
              v-if="scope.row.status == 1"
              class="btn"
              @click="handleLook(2, scope.row)"
              >预览</span
            >
            <span
              class="btn"
              v-if="scope.row.status == 2"
              @click="handleUnder(scope.row, 1)"
              >上架</span
            >
            <span
              class="btn"
              v-if="scope.row.status == 1"
              @click="handleUnder(scope.row, 2)"
              >下架</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        :page-size="page_size"
        layout="prev, pager, next"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  goodsList,
  getSchoolList,
  getClassList,
  changeStatus,
} from "@/api/admin.js";
import { getMainCategory } from "@/api/common.js";
export default {
  data() {
    return {
      formInline: {
        id: "",
        name: "",
        shop_name: "",
        category_id: "",
        status: "",
        school_id: "",
        class_id: "",
      },
      tableData: {
        list: [],
      },
      categoryList: [],
      schoolList: "",
      classList: "",
      page: 1,
      page_size: 10,
      loading: true,
    };
  },
  created() {
    this.getFlList();
    this.getCampusList();
    this.getClassLt();
    this.getGoodsList();
  },
  methods: {
    getGoodsList() {
      this.loading = true;
      // localStorage.USERTYPE = 4;
      goodsList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.formInline.class_id = "";
      getClassList({
        school_id: this.formInline.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    getFlList() {
      getMainCategory().then((res) => {
        if (res.code == 1) {
          this.categoryList = res.data;
        }
      });
    },
    handleReset() {
      this.formInline = {
        id: "",
        name: "",
        shop_name: "",
        category_id: "",
        status: "",
        school_id: "",
        class_id: "",
      };
      this.page = 1;
      this.getGoodsList();
    },
    pageChange(index) {
      this.page = index;
      this.getGoodsList();
    },
    handleLook(type, val) {
      if (type == 1) {
        sessionStorage.ADMIN_GOODSID = val.id;
        this.$router.push("adminGoodsInfo");
      }
      if (type == 2) {
        // this.$router.push({ path: "goodsInfo", query: { goodsId: val.id } });
        window.open(
          "goodsInfo?goodsId=" + val.id + "&school_id=" + val.school_id,
          "_blank"
        );
      }
    },
    handleUnder(val, type) {
      let text = type == 1 ? "上架" : "下架";
      let text2 =
        type == 2
          ? "商品下架后不在店铺前台展示, 是否确认?"
          : "是否确认上架该商品?";
      this.$confirm(text2, "商品" + text, {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        changeStatus({
          goods_id: val.id,
          status: type,
        }).then((res) => {
          if (res.code == 1) {
            val.status = type;
            this.$message.success(text + "成功");
          }
        });
      });
    },
  },
};
</script>
<style lang="less" >
.admin-goods {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
// .el-table--border .el-table__cell {
//   border-color: #000;
// }
</style>